<template>
  <v-container fluid>
    <v-toolbar  height="50" class="mx-6 mb-2 mt-2" elevation="4" rounded>
      <ImportMenu />
    </v-toolbar>
      <router-view></router-view>

  </v-container>
</template>

<script>
import ImportMenu from './Menu.vue';
export default {
  components: { ImportMenu }
};
</script>
