<template>
  <v-toolbar-items>
    <sub-menu :name="menuLabel" :menu-items="pages" @sub-menu-click="onMenuItemClick" showDropDown />
  </v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';
import SubMenu from '@/components/SubMenu.vue';

export default {
  name: 'ImportMenu',
  inject: ['eventHub'],
  components: { SubMenu },
  data() {
    return {
      currentPage: '',
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    pages() {
      return [
        {
          name: 'CSV',
          page: '',
        },
        {
          name: 'Traversa',
          page: 'traversa',
        },
      ];
    },
    menuLabel() {
      return this.currentPage == 'traversa' ? 'Traversa' : 'CSV';
    },
  },
  created() {
    this.currentPage = this.$route.name;
  },
  methods: {
    onMenuItemClick(item) {
      this.$router.push(`/import/${item.page}`);
    },
  },
  watch: {
    $route(to, from) {
      this.currentPage = to.name;
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
}
</style>
